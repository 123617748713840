/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import tech_pioneer from '../../assets/tech-pioneer.png'

const TechnologyPioneer = () => {
  return (
    <div className='flex justify-center items-center w-full'>
      <div className='flex sm:flex-row flex-col justify-center items-center w-full sm:py-0 py-5 lg:px-3 px-3 mt-5 pb-16 max-w-7xl'>

        <div className=' flex justify-center items-center sm:w-1/2 w-full lg:h-[40rem]'>
          <img src={tech_pioneer} alt="logo" className='lg:h-[29rem] object-cover'
          />
        </div>

        <div className='sm:w-1/2 w-full px-3 font-Poppins sm:mt-0 mt-2 bg-cover bg-center bg-no-repeat'
          // style={{ backgroundImage: `url(${back_1})` }}
        >
          <h1 className='text-orange-500 font-mono'>TECHNOLOGY PIONEERS</h1>
          <h2 className='lg:py-10 py-5 text-blue-950 font-bold text-2xl'>Unveiling the IMO-Tech Solutions Experience</h2>
          <p className='pb-10 text-gray-700 font-semibold text-xl'>
            Embark on a Journey of Technological <br/> Ingenuity and Innovation with IMO-Tech
          </p>

          <p className='text-lg text-gray-700 font-Archivo max-w-[30rem] '>
            Founded in 2022, IMO-Tech emerged from a shared vision among pioneers determined to redefine the
            digital landscape. Today, we have evolved into a powerhouse of creativity and skill, offering an
            extensive array of services that empower businesses and individuals in the ever-evolving digital frontier.
          </p>

          {/* <div className='flex flex-row lg:gap-6 gap-4 w-full mt-5 '>

            <a target="_blank" href='#'>
              <svg className="w-6 text-blue-500 bounce-on-hover"
              fill="currentColor"
              viewBox="0 0 512 512"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"></path>
              </svg>
            </a>

            <a target="_blank" href='#'>
              <svg className="w-6 text-red-500 bounce-on-hover"
                fill="currentColor"
                viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
                <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
              </svg>
            </a>

            <a target="_blank" href='#'>
              <svg className="w-6 text-blue-500 bounce-on-hover"
                fill="currentColor"
                viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
                <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"></path>
              </svg>
            </a>

            <a target="_blank" href='#'>
              <svg className="w-6 text-red-500 bounce-on-hover"
                fill="currentColor"
                viewBox="0 0 496 512"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M496 256c0 137-111 248-248 248-25.6 0-50.2-3.9-73.4-11.1 10.1-16.5 25.2-43.5 30.8-65 3-11.6 15.4-59 15.4-59 8.1 15.4 31.7 28.5 56.8 28.5 74.8 0 128.7-68.8 128.7-154.3 0-81.9-66.9-143.2-152.9-143.2-107 0-163.9 71.8-163.9 150.1 0 36.4 19.4 81.7 50.3 96.1 4.7 2.2 7.2 1.2 8.3-3.3.8-3.4 5-20.3 6.9-28.1.6-2.5.3-4.7-1.7-7.1-10.1-12.5-18.3-35.3-18.3-56.6 0-54.7 41.4-107.6 112-107.6 60.9 0 103.6 41.5 103.6 100.9 0 67.1-33.9 113.6-78 113.6-24.3 0-42.6-20.1-36.7-44.8 7-29.5 20.5-61.3 20.5-82.6 0-19-10.2-34.9-31.4-34.9-24.9 0-44.9 25.7-44.9 60.2 0 22 7.4 36.8 7.4 36.8s-24.5 103.8-29 123.2c-5 21.4-3 51.6-.9 71.2C65.4 450.9 0 361.1 0 256 0 119 111 8 248 8s248 111 248 248z"></path>
              </svg>
            </a>

            <a target="_blank" href='#'>
              <svg className="w-6 h-6 text-gray-800 bounce-on-hover"
                fill="currentColor"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24">
                <path  d="M13.8 10.5 20.7 2h-3l-5.3 6.5L7.7 2H1l7.8 11-7.3 9h3l5.7-7 5.1 7H22l-8.2-11.5Zm-2.4 3-1.4-2-5.6-7.9h2.3l4.5 6.3 1.4 2 6 8.5h-2.3l-4.9-7Z"/>
              </svg>
            </a>

					  <a target="_blank" href='#'>
              <svg className="w-6 text-red-500 bounce-on-hover"
                fill="currentColor"
                viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg">
                <path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path>
              </svg>
            </a>
          </div> */}
        </div>
      </div>
      </div>
  );
}

export default TechnologyPioneer;

import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../assets/logo.png";

const navigation = [
  { name: "HOME", href: "/" },
  { name: "ABOUT US", href: "/about" },
  { name: "SERVICES", href: "/services" },
  { name: "PROJECTS", href: "/projects" },
  { name: "CONTACT US", href: "/contact_us" },
];

function NavBar() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const [activeNav, setActiveNav] = useState("/");

  useEffect(() => {
    const savedNav = localStorage.getItem("currentNav");
    const currentPath = location.pathname;
    setActiveNav(savedNav && savedNav === currentPath ? savedNav : currentPath);
  }, [location]);

  const handleNavClick = (href) => {
    localStorage.setItem("currentNav", href);
    setActiveNav(href);
    setIsOpen(false);
  };

  return (
    <nav className="w-full z-10 text-sm font-Poppins text-white font-bold p-2" aria-label="Top">
      <div className="max-w-[95rem] mx-auto px-4 sm:px-6 py-4 lg:px-8">
        <div className="flex justify-between items-center">
          {/* Logo */}
          <div className="flex items-center">
            <NavLink to="/" className="text-sm font-bold text-blue-500">
              <img className="h-10 w-auto" src={logo} alt="IMO Tech" />
            </NavLink>
          </div>

          {/* Desktop Menu */}
          <div className="hidden sm:flex justify-center w-full">
            <div className="flex items-center gap-6">
              {navigation.map((item) => (
                <NavLink
                  key={item.name}
                  to={item.href}
                  className={`px-4 py-2 text-white font-semibold transition duration-200 ease-in-out ${
                    activeNav === item.href ? "underline underline-offset-4 decoration-blue-200" : "hover:font-bold"
                  }`}
                  onClick={() => handleNavClick(item.href)}
                >
                  {item.name}
                </NavLink>
              ))}
            </div>
          </div>

          {/* Mobile Menu Button */}
          <div className="sm:hidden">
            <button onClick={() => setIsOpen(!isOpen)} className="focus:outline-none">
              <svg className="h-6 w-6" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="sm:hidden bg-gray-800">
          <div className="flex flex-col items-center gap-4 py-4">
            {navigation.map((item) => (
              <NavLink
                key={item.name}
                to={item.href}
                className="block text-white text-base hover:text-orange-500 transition duration-200"
                onClick={() => handleNavClick(item.href)}
              >
                {item.name}
              </NavLink>
            ))}
          </div>
        </div>
      )}
    </nav>
  );
}

export default NavBar;
